@if (physician) {
	<div class="fx-layout-column" style="height: calc(100vh - 180px)">
		@if (exams && !loading) {
			<div class="fx-layout-column" style="height: calc(100vh - 128px)">
				<div
					class="feat-card-lg fx-layout-row-nowrap fx-content-space-evenly fx-items-center fx-gap-12">
					<div style="transform: scale(1.6)">
						<div class="header">Total ({{ currencyFormat }})</div>
						<div class="body feat-text">
							{{ formatNumeral(calculateTotalAmount(exams)) }}
						</div>
					</div>

					<div class="fx-layout-column fx-gap-16">
						<div>
							<div class="header">
								{{ 'WORKING_DAYS' | translate }}
							</div>
							<div class="body">{{ days }}</div>
						</div>
						<div>
							<div class="header">
								{{ 'DAILY_AVG' | translate }} ({{
									currencyFormat
								}})
							</div>
							<div class="body">
								{{
									formatNumeral(
										calculateDailyAvg(exams, days)
									)
								}}
							</div>
						</div>
					</div>

					<div class="fx-layout-column fx-gap-16">
						<div>
							<div class="header">
								{{ 'NUMBER_OF_EXAMS' | translate }}
							</div>
							<div class="body">{{ totalExams }}</div>
						</div>
						<div>
							<div class="header">
								{{ 'AVG_BY_EXAM' | translate }} ({{
									currencyFormat
								}})
							</div>
							<div class="body">
								{{
									formatNumeral(
										calculateExamsAvg(exams, totalExams)
									)
								}}
							</div>
						</div>
					</div>
				</div>

				<div
					class="grid-exams fx-grow-1 fx-overflow-auto fx-fill-height">
					<div
						class="cell day fw-600 fx-layout-row-nowrap fx-fill-width">
						<div
							[style.padding-left.px]="24"
							[style.text-align]="'left'"
							class="fx-grow-1">
							Date
						</div>
						<div [style.text-align]="'left'" class="fx-grow-1">
							Exam
						</div>
						@if (feeType === 'EXAM') {
							<div style="width: 120px">Tarif</div>
						}
						<div style="width: 120px">Nbre</div>
						@if (feeType === 'EXAM') {
							<div style="width: 120px">
								Total({{ currencyFormat }})
							</div>
						}
					</div>
					@for (e of exams; track e) {
						<div>
							<div
								class="cell day fx-layout-row-nowrap fx-fill-width">
								<div
									[style.padding-left.px]="24"
									[style.text-align]="'left'"
									class="fx-grow-1">
									{{ e.date | date: 'dd/MM/yyyy' }}
								</div>
								<div
									[style.text-align]="'left'"
									class="fx-grow-1">
									{{ e.exam }}
								</div>
								@if (feeType === 'EXAM') {
									<div style="width: 120px">
										{{ formatNumeral(e.tariff) }}
									</div>
								}
								<div style="width: 120px">{{ e.count }}</div>
								@if (feeType === 'EXAM') {
									<div style="width: 120px">
										{{ formatNumeral(e.total) }}
									</div>
								}
							</div>
						</div>
					}
				</div>
			</div>
		} @else {
			<div
				class="fx-grow-1 fx-layout-row-nowrap fx-content-center fx-items-center fx-gap-4">
				<mat-spinner [diameter]="42" [strokeWidth]="2"></mat-spinner>
				<div class="fz-16 fw-600">{{ 'LOADING' | translate }}</div>
			</div>
		}
	</div>
} @else {
	<div
		class="fx-layout-row-nowrap fx-content-center fx-items-center"
		style="font-style: italic; height: 80vh">
		<h3>{{ 'PLEASE_SELECT_PHYSICIAN' | translate }}</h3>
	</div>
}
