<div class="fx-layout-column ref-table" style="min-height: calc(100vh - 204px)">
	<mat-table
		[dataSource]="dataSource"
		class="fx-overflow-auto"
		style="height: calc(100vh - 197px)"
		[trackBy]="trackById"
		matSort
		[matSortActive]="'referringPhysician'"
		[matSortDirection]="'asc'"
		matSortDisableClear>
		<!--Table-->
		@for (col of displayedColumns; track col) {
			<ng-container
				cdkColumnDef="{{ col.label }}"
				[class.hidden]="col.hidden">
				<mat-header-cell
					*cdkHeaderCellDef
					mat-sort-header="{{ col.value }}"
					[disabled]="!col['sortable']">
					{{ col.header | translate }}
				</mat-header-cell>

				@if (col.type !== 'number') {
					<mat-cell *cdkCellDef="let row">
						{{ row[col.label] }}
					</mat-cell>
				}

				@if (col.type === 'number') {
					<mat-cell
						*cdkCellDef="let row"
						style="text-align: right; font-weight: bold">
						{{ row[col.label] }}
					</mat-cell>
				}
			</ng-container>
		}

		<!--header-->
		<mat-header-row
			*cdkHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
		<mat-row
			[@rowsAnimation]=""
			*cdkRowDef="let row; columns: columnsToDisplay"
			[ngClass]="{ 'fire-row': true, hide: row.hidden }"></mat-row>
	</mat-table>
	<div
		class="fx-table-empty"
		[style.display]="resultsLength === 0 ? '' : 'none'">
		@if (isLoadingResults) {
			<div>
				<mat-spinner
					color="warn"
					[diameter]="50"
					[strokeWidth]="3"></mat-spinner>
			</div>
		} @else {
			{{ 'NOITEMSFOUND' | translate }}
		}
	</div>

	<mat-paginator
		[length]="resultsLength"
		[pageIndex]="0"
		[pageSize]="20"
		[pageSizeOptions]="[10, 20, 50, 100]"
		[showFirstLastButtons]="true">
	</mat-paginator>
</div>
