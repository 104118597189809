import { ColumnDataType, TableColumn } from '../../model';

export const FILES_COLUMNS: TableColumn[] = [
	{
		label: 'date',
		value: 'date',
		hidden: false,
		sortField: 'date',
		header: 'DATE',
		sortable: true,
		searchable: true,
		required: false,
		order: 1,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: 'date',
	},
	{
		label: 'name',
		value: 'name',
		hidden: false,
		sortField: 'name',
		header: 'NAME',
		sortable: false,
		searchable: true,
		required: false,
		order: 2,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: null,
	},

	{
		label: 'category',
		value: 'category',
		hidden: false,
		sortField: 'category',
		header: 'CATEGORY',
		sortable: true,
		searchable: true,
		required: false,
		order: 3,
		id: 0,
		type: ColumnDataType.STRING,
		defaultValue: '',
		selected: true,
		unit: 'color',
	},
];

export const FILES_CATEGORIES_ICONS = {
	Report: 'mdi-file-document',
	Receipt: 'mdi-receipt-text',
	Ticket: 'mdi-barcode',
	undefined: 'mdi-file',
};

export const FILES_CATEGORIES_COLORS = {
	Report: '#00a9ff',
	Receipt: '#ffb200',
	Ticket: '#ff006a',
	undefined: '#555555',
};
