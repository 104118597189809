export const LOCALIZATION = {
	fieldsList: {
		flatHierarchyBox: 'Sélectionner et Organiser les colonnes',
		hierarchyBox: 'Glisser Les Dimensions',
		filterBox: 'Glisser & Organiser Des Filtres De Rapport',
		rowBox: 'Glisser & Trier Les Lignes',
		columnBox: 'Glisser & Organiser Les Colonnes',
		measureBox: 'Glisser & Organiser Les Valeurs',
		values: 'Valeurs',
		addCalculatedMeasure: 'Ajouter une valeur calculée',
		expandAll: 'Développez Tous',
		collapseAll: 'Grouper toutes Les',
		formulasGroupName: 'Les Valeurs Calculées',
		allFields: 'Tous Les Champs',
		rows: 'Les lignes',
		columns: 'Les colonnes',
		filters: 'Des Filtres De Rapport',
		dropField: 'Baisse de domaine ici',
		title: 'Champs',
		subtitle: "Faites glisser et déposez des champs d'organiser",
	},
	filter: {
		all: 'Tous',
		multipleItems: 'Plusieurs Éléments',
		selectAll: 'Sélectionnez tous ',
		selectAllResults: 'Sélectionnez tous Les Résultats',
		top: 'Top',
		bottom: 'Bas',
		ascSort: 'Az',
		descSort: 'zA',
		topX: 'Top 10',
		clearTopX: 'Vider',
		measuresPrompt: 'Choisissez la valeur',
		search: 'Recherche',
		amountSelected: '{0}/{1} sélectionné(s)',
		amountFound: '{0} {1} sont sélectionnés',
		sort: 'Tri:',
		addGroup: 'Ajouter un groupe',
		groupName: 'Groupe 1',
		ungroup: 'Dissocier',
	},
	drillThrough: {
		title: 'Détails',
		row: 'Ligne: <b>{0}</b>',
		column: 'Colonne: <b>{0}</b>',
		value: '{0}: <b>{1}</b>',
	},
	calculatedView: {
		title: 'Valeur Calculée',
		measureBox: 'Faites glisser les valeurs à la formule',
		measureName: 'Nom de la valeur',
		formula: 'Formule',
		formulaPrompt: 'Glissez des valeurs et modifier la formule ici',
		calculateIndividualValues: 'Calculer les valeurs individuelles',
		removeValue: 'Supprimer',
		removeValueTitle: 'Supprimer {0}?',
		removeValueMessage:
			'Êtes-vous sûr de vouloir supprimer cette valeur calculée?',
		header: 'Ajouter de la valeur calculée',
		allValues: 'Toutes les valeurs',
	},
	grid: {
		total: 'Sous total',
		totals: 'Les totaux',
		grandTotal: 'Total',
		blankMember: ' ',
		dateInvalidCaption: 'Date non valide',
		reportInformation: "Rapport D'Information",
	},
	tooltips: {
		row: 'Ligne:',
		column: 'Colonne:',
		headerResize: 'Faites glisser pour redimensionner',
		headerFit: "Double-cliquez pour l'ajustement",
		filterIcon: 'Cliquez pour filtrer',
		filtered: 'Filtré',
		expandIcon: 'Cliquer pour agrandir',
		collapseIcon: "Cliquez sur l'effondrement",
		drillDown: 'Cliquez pour forer vers le bas',
		drillUp: 'Cliquez sur explorer',
		sortIcon: 'Cliquez pour trier Desc',
		sortedDescIcon: 'Cliquez pour trier Asc',
		sortedAscIcon: 'Cliquez pour trier Desc',
		close: 'Cliquez pour fermer',
	},
	aggregations: {
		sum: {
			caption: 'Somme',
			totalCaption: 'Somme de {0}',
			grandTotalCaption: 'Somme de {0}',
		},
		count: {
			caption: 'Compte',
			totalCaption: 'Le comte de {0}',
			grandTotalCaption: 'Nombre Total de {0}',
		},
		distinctCount: {
			caption: 'Compte distint',
			totalCaption: 'Nombre Distinct de {0}',
			grandTotalCaption: 'Total nombre Distinct de {0}',
		},
		difference: {
			caption: 'Différence',
			totalCaption: 'Différence de {0}',
			grandTotalCaption: 'Différence de {0}',
		},
		percentDifference: {
			caption: '% De différence',
			totalCaption: '% De différence de {0}',
			grandTotalCaption: '% De différence de {0}',
		},
		average: {
			caption: 'Moyenne',
			totalCaption: 'Moyenne de {0}',
			grandTotalCaption: 'Moyenne totale de {0}',
		},
		product: {
			caption: 'Produit',
			totalCaption: 'Produit de {0}',
			grandTotalCaption: 'Total du Produit de {0}',
		},
		min: {
			caption: 'Min',
			totalCaption: 'Min de {0}',
			grandTotalCaption: 'Total Min de {0}',
		},
		max: {
			caption: 'Max',
			totalCaption: 'Max de {0}',
			grandTotalCaption: 'Total Max de {0}',
		},
		percent: {
			caption: '% du Grand Total',
			totalCaption: '% du Grand Total de {0}',
			grandTotalCaption: 'Total en % du Total de {0}',
		},
		percentOfColumn: {
			caption: '% de Colonne',
			totalCaption: '% de la Colonne de {0}',
			grandTotalCaption: 'Total % de la Colonne de {0}',
		},
		percentOfRow: {
			caption: '% de la Ligne',
			totalCaption: '% de la Ligne de {0}',
			grandTotalCaption: 'Total % de la Ligne de {0}',
		},
		index: {
			caption: 'Index',
			totalCaption: 'Indice de {0}',
			grandTotalCaption: 'Indice de {0}',
		},
		none: {
			caption: 'Aucun Calcul',
		},
	},
	messages: {
		error: 'Erreur!',
		warning: 'Avertissement!',
		limitation: 'Limitation!',
		browse: 'Parcourir',
		confirmation: 'Confirmation',
		reportFileType: 'Flexmonster fichier de rapport',
		loading: 'Chargement...',
		loadingConfiguration: '',
		loadingData: 'Chargement des données...',
		waiting: "L'attente de la réponse {0} secondes.",
		progress: '{0}K',
		progressUnknown: 'chargé {0}K',
		analyzing: "L'analyse des données...",
		analyzingProgress: '{0} registres de {1} ({2}%)',
		analyzingRecords: '{0}% des dossiers',
		saving: 'Enregistrement...',
		loadingDimensions: 'Chargement dimensions...',
		loadingHierarchies: 'Chargement des hiérarchies...',
		loadingMeasures: 'Chargement de mesures...',
		loadingKPIs: 'Chargement Des Kpi...',
		loadingMembers: 'Chargement de membres...',
		loadingLevels: 'Niveaux de chargement...',
		loadingProperties: 'Chargement des propriétés...',
		fullscreen: 'Ouvrir le rapport en plein écran?',
		exportComplete:
			'L\'exportation de données est créé, veuillez cliquer sur le bouton "Enregistrer" pour enregistrer le _data.',
		exportProgress: 'Exportation en cours...',
		exportError:
			"Échec à l'exportation. Une erreur inattendue s'est produite.",
		generatingPDF: 'Génération de PDF',
		pleaseWait: 'Veuillez patienter.',
		pagesWereGenerated: 'les pages ont été générés.',
		uploading: 'Le téléchargement de...',
		cantSaveFile: 'Ne peut pas enregistrer le fichier.',
		cantSaveToClipboard:
			"Erreur: Impossible d'écrire dans le presse-papiers.",
		saveReportToFile:
			'Le rapport est prêt à être enregistrées dans un fichier, veuillez cliquer sur le bouton "Enregistrer" pour enregistrer le rapport.',
		loadReportFromFile: 'Sélectionnez fichier de rapport pour être chargé.',
		inputNewName: 'Entrer le nouveau nom',
		inputReportName: 'Entrez le nom de rapport',
		invalidDataSource:
			'Invalide de la source de données ou un catalogue. Veuillez vérifier.',
		dataStreamError:
			"Stream erreur s'est produite lors du chargement '{0}'",
		unableToOpenFile: "Impossible d'ouvrir le fichier {0}.",
		unableTwoFileBrowsingSessions:
			'Parcourir de la boîte de dialogue de fichier est déjà ouvert.',
		inappropriateFileFormat:
			'Le fichier de données est inappropriée de format.',
		invalidJSONdata: "Les données JSON n'est pas valide.",
		wrongFormulaFormat: 'La mauvaise formule de format. Veuillez vérifier.',
		excelCsvChartsExportError:
			"Exportation vers Microsoft Excel ou CSV n'est pas disponible pour les graphiques.",
		excelPdfExportLimitation:
			"Exportation vers Microsoft Excel ou PDF n'est pas disponible dans l'édition actuelle.",
		excelExportLimitation:
			"L'exportation n'est pas disponible dans l'édition actuelle.",
		noDataAvailable:
			'Source de données est vide. Veuillez vérifier le fichier CSV.',
		saveDataToFile:
			'Les données sont prêtes à être enregistrées dans un fichier, veuillez cliquer sur le bouton "Enregistrer" pour enregistrer le fichier.',
		dataWasUpdated:
			'Source de données a été mis à jour sur le serveur. Actualiser le rapport?',
		ocsvIncompatible:
			'Impossible de lire les données de la source. Il semble que OCSV fichier a été compressé avec une version plus récente. Veuillez mettre à jour le composant à la version {0} ou plus récent.',
		unknownError: "Erreur inconnue s'est produite.",
		invalidReportFormat:
			"Invalide format de rapport ou de l'accès au dossier est refusé.",
		csvHeaderParsingError: "CSV-tête de l'erreur d'analyse.",
		tooManyColumnsInClassicMode:
			'Trop de colonnes de forme classique. Commutation de la maquette à la forme compacte.',
		cantExpand:
			"Certains champs ne peuvent pas être élargi. Veuillez affiner l'ensemble de données.",
		cantExpandTitle: 'Ensemble de données est trop grande',
	},
	buttons: {
		ok: 'OK',
		apply: 'Appliquer',
		cancel: 'Annuler',
		save: 'Enregistrer',
		clear: 'Vider',
		select: 'Sélectionnez',
		yes: 'Oui',
		no: 'Pas de',
	},
	contextMenu: {
		clearSorting: 'Annuler le tri',
		collapse: "L'effondrement",
		drillThrough: 'Percer',
		expand: 'Développez',
		openFilter: 'Ouvrir filtre',
		sortColumnAsc: 'Colonne de tri asc',
		sortColumnDesc: 'Colonne de tri desc',
		sortRowAsc: 'Ligne de tri asc',
		sortRowDesc: 'Ligne de tri desc',
	},
	date: {
		year: 'Année',
		quarter: 'Trimestre',
		month: 'Mois',
		day: 'Jour',
	},
	quarters: {
		q1: 'Trimestre 1',
		q2: 'Trimestre 2',
		q3: 'Trimestre 3',
		q4: 'Trimestre 4',
	},
	months: {
		january: 'Janvier',
		february: 'Février',
		march: 'Mars',
		april: 'Avril',
		may: 'Mai',
		june: 'Juin',
		july: 'Juillet',
		august: 'Août',
		september: 'Septembre',
		october: 'Octobre',
		november: 'Novembre',
		december: 'Décembre',
	},
	monthsShort: {
		january: 'Jan',
		february: 'Fév',
		march: 'Mar',
		april: 'Avr',
		may: 'Mai',
		june: 'Jun',
		july: 'Jul',
		august: 'Aoû',
		september: 'Sep',
		october: 'Oct',
		november: 'Nov',
		december: 'Dec',
	},
	weekdays: {
		first: 'Dimanche',
		second: 'Lundi',
		third: 'Mardi',
		fourth: 'Mercredi',
		fifth: 'Jeudi',
		sixth: 'Vendredi',
		seventh: 'Samedi',
	},
	weekdaysShort: {
		first: 'Dim',
		second: 'Lun',
		third: 'Mar',
		fourth: 'Mer',
		fifth: 'Jeu',
		sixth: 'Ven',
		seventh: 'Sam',
	},
	toolbar: {
		connect: 'Connecter',
		connect_local_csv: 'CSV local',
		connect_local_ocsv: 'Local OCSV',
		connect_local_json: 'JSON local',
		connect_remote_csv: 'CSV externe',
		connect_remote_csv_mobile: 'CSV',
		connect_remote_json: 'JSON externe',
		connect_remote_json_mobile: 'JSON',
		open: 'Ouvrir',
		local_report: 'Rapport Local',
		remote_report: 'Rapport à distance',
		remote_report_mobile: 'Rapport',
		save: 'Enregistrer',
		save_json: 'Enregister JSON',
		load_json: 'Charger JSON',
		grid: 'Grille',
		grid_flat: 'Plat',
		grid_classic: 'Classique',
		grid_compact: 'Compact',
		format: 'Format',
		format_cells: 'Format de cellule',
		format_cells_mobile: 'Format',
		conditional_formatting: 'La mise en forme conditionnelle',
		conditional_formatting_mobile: 'Conditionnel',
		options: 'Options',
		fullscreen: 'Plein écran',
		minimize: 'Minimiser',
		export: 'Exporter',
		export_print: 'Imprimer',
		export_html: 'HTML',
		export_excel: 'Pour Excel',
		export_pdf: 'PDF',
		fields: 'Champs',
		ok: 'OK',
		apply: 'Appliquer',
		done: 'Fait',
		cancel: 'Annuler',
		value: 'Valeur',
		delete: 'Supprimer',
		if: 'Si',
		then: 'Alors',
		open_remote_csv: 'Ouverte CSV à distance',
		open_remote_json: 'Ouvrir JSON à distance',
		csv: 'CSV',
		open_remote_report: 'Ouverir un rapports à distance',
		choose_value: 'Choisissez la valeur',
		text_align: 'Aligner texte',
		align_left: 'de gauche',
		align_right: 'droit',
		none: 'Aucun',
		space: '(Espace)',
		thousand_separator: 'Séparateur de milliers',
		decimal_separator: 'Séparateur décimal',
		decimal_places: 'Décimales',
		currency_symbol: 'Symbole de devise',
		currency_align: 'Aligner symbole',
		null_value: 'Valeur Null',
		is_percent: 'Format pour cent',
		true_value: 'vrai',
		false_value: 'faux',
		conditional: 'Conditionnel',
		add_condition: 'Ajouter une condition',
		less_than: 'Moins de',
		less_than_or_equal: 'Inférieure ou égale à',
		greater_than: 'Plus de',
		greater_than_or_equal: 'Supérieure ou égale à',
		equal_to: 'Égal à',
		not_equal_to: 'Pas égal à',
		between: 'Entre',
		is_empty: 'Vide',
		all_values: 'Toutes les valeurs',
		and: 'et',
		and_symbole: '&',
		cp_text: 'Texte',
		cp_highlight: 'Mettre en évidence',
		layout_options: 'Options de mise en page',
		layout: 'Mise en page',
		compact_view: 'Forme compacte',
		classic_view: 'Forme classique',
		flat_view: 'Forme plate',
		grand_totals: 'Les totaux généraux',
		grand_totals_off: 'Ne pas afficher les totaux généraux',
		grand_totals_on: 'Afficher les totaux généraux',
		grand_totals_on_rows: 'Afficher uniquement pour les lignes',
		grand_totals_on_columns: 'Afficher pour les colonnes uniquement',
		subtotals: 'Les sous-totaux',
		subtotals_off: 'Ne pas afficher les sous-totaux',
		subtotals_on: 'Afficher les sous-totaux',
		subtotals_on_rows: 'Afficher les lignes de sous-total seulement',
		subtotals_on_columns: 'Afficher les sous-total des colonnes seulement',
		choose_page_orientation: "Choisissez l'orientation de la page",
		landscape: 'Paysage',
		portrait: 'Portrait',
	},
	_id: 'ID',
};
