<div class="fx-layout-column fx-content-center fx-items-center">
	<div class="grid-parent">
		@for (state of dataMap; track state) {
			<div class="grid-item">
				<div class="a-table">
					<div class="a-row a-header">
						<div>{{ state.label | uppercase | translate }}</div>
						<div>{{ 'TOTAL' | translate }}</div>
					</div>
					@for (val of state.data; track val) {
						<div class="a-row">
							<div>{{ val.value }}</div>
							<div style="text-align: center">
								{{ val.count }}
							</div>
						</div>
					}
				</div>

				<ft-chart
					[data]="state.dataset"
					[labels]="state.chartLabels"
					[chartId]="state.label"
					[type]="state.chartType"></ft-chart>
			</div>
		}
	</div>
</div>
