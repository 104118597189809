export const MONTHS = [
	{ id: 0, name: '--Tous--' },
	{ id: 1, name: 'Janvier' },
	{ id: 2, name: 'Février' },
	{ id: 3, name: 'Mars' },
	{ id: 4, name: 'Avril' },
	{ id: 5, name: 'Mai' },
	{ id: 6, name: 'Juin' },
	{ id: 7, name: 'Juillet' },
	{ id: 8, name: 'Août' },
	{ id: 9, name: 'Septembre' },
	{ id: 10, name: 'Octobre' },
	{ id: 11, name: 'Novembre' },
	{ id: 12, name: 'Décembre' },
];
