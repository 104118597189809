import { Component, Input, OnInit } from '@angular/core';
import { StateComponent, ViewQuery } from '../model';
import { SharedService } from '../../shared';
import { StatisticService } from '../statistic.service';
import moment from 'moment';
import numeral from 'numeral';
import { AppConfigService } from '../../app-config.service';
import { orderBy } from 'lodash';

@Component({
	selector: 'ft-physician-exam-state',
	templateUrl: './physician-exam-state.component.html',
	styleUrls: ['./physician-exam-state.component.scss'],
})
export class PhysicianExamStateComponent implements StateComponent, OnInit {
	@Input() data: ViewQuery;

	exams = [];
	physician: any;
	loading = true;
	currencyFormat = 'DH';
	days: number = 0;
	totalExams: number = 0;
	feeType: any;

	constructor(
		private shared: SharedService,
		private service: StatisticService,
		private _config: AppConfigService
	) {
		this.currencyFormat = _config.currencyFormat;
	}

	ngOnInit(): void {
		this.physician = !!this.data.filter;
		if (this.data) this.buildDataTable();
	}

	buildDataTable() {
		const start = moment(this.data.startDate).format('YYYYMMDD');
		const end = moment(this.data.endDate).format('YYYYMMDD');
		this.loading = true;
		if (this.data.filter != '')
			this.service
				.getPhysicianExams(`${start}-${end}-${this.data.filter}`)
				.subscribe(data => {
					this.exams =
						data && data.length != 0
							? orderBy(data[0]['exams'], 'date', 'desc')
							: [];
					this.days = data && data.length != 0 ? data[0]['days'] : 0;
					this.totalExams =
						data && data.length != 0 ? data[0]['totalExams'] : 0;
					this.feeType =
						data && data.length != 0 ? data[0]['feeType'] : '';

					this.loading = false;
				});
	}

	calculateTotalAmount(exams: any[]): number {
		return exams.map(it => it.total).reduce((a, c) => a + c, 0);
	}

	formatNumeral(numValue: any): any {
		return numeral(numValue).format(`0,0.00`);
	}

	calculateDailyAvg(exams: any[], days: number): number {
		return days != 0 ? this.calculateTotalAmount(exams) / days : 0;
	}

	calculateExamsAvg(exams: any[], totalExams: number): number {
		return totalExams != 0
			? this.calculateTotalAmount(exams) / totalExams
			: 0;
	}
}
