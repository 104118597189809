@if (state) {
	<div class="fx-layout-row fx-gap-12 fx-content-center" style="height: 76vh">
		<div class="payment-table" style="width: 400px">
			<div class="payment-caisse mat-elevation-z3">
				<div class="header">{{ 'CENTER_CHECKOUT' | translate }}</div>
				<div class="payment-grid">
					<div>{{ 'DAY_SUM' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(state.daily_paid_amount) }}
					</div>
					<div>{{ 'ANTERIOR_SUM' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(state.anterior_paid_amount) }}
					</div>
					<div class="border-top">
						{{ 'FINAL_CHECKOUT' | translate }}
					</div>
					<div
						[style.textAlign]="'right'"
						class="border-top feat-number"
						style="font-weight: bold">
						{{ formatNumeral(finalSold) }}
					</div>
				</div>
			</div>

			<div class="payment-caisse mat-elevation-z3">
				<div class="header">{{ 'VALUES' | translate }}</div>
				<div class="payment-grid">
					<div>{{ 'VALUE' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(state.daily_taxed_amount) }}
					</div>
					<div>{{ 'BILLED' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(state.daily_paid_amount) }}
					</div>
					<div>{{ 'REGULATED' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(state.daily_paid_amount) }}
					</div>
					<div>{{ 'NOT_PAID' | translate }}</div>
					<div [style.textAlign]="'right'">
						{{ formatNumeral(notPaid) }}
					</div>
				</div>
				<div class="footer">
					{{ state.nb_patient_visits }} patient(s)
				</div>
			</div>

			<div class="payment-caisse mat-elevation-z3">
				<div class="header">{{ 'PAYMENTS_BY_PEC' | translate }}</div>
				<div class="payment-grid">
					<div>{{ 'PATIENT' | translate }}</div>
					<div class="fx-layout-row fx-content-space-between calcul">
						<div class="count fx-grow-1">
							{{ state.nb_patient_visits || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(totalPatientAmount) }}
						</div>
					</div>
				</div>
				<div class="payment-grid">
					<div>{{ 'THIRD_PAYER' | translate }}</div>
					<div class="fx-layout-row fx-content-space-between calcul">
						<div class="count fx-grow-1">
							{{ state.nb_organization_visits || 0 }}
						</div>
						<div class="fx-grow-1">0.00</div>
					</div>
				</div>
				<div class="payment-grid">
					<div>{{ 'OTHER' | translate }}</div>
					<div class="fx-layout-row fx-content-space-between calcul">
						<div class="count fx-grow-1">0</div>
						<div class="fx-grow-1">0.00</div>
					</div>
				</div>

				<div class="payment-grid border-top">
					<div>TOTAL</div>
					<div
						class="calcul"
						class="fx-layout-row fx-content-space-between"
						style="padding: 0 !important">
						<div class="count feat-number fx-grow-1">
							{{ state.nb_patient_visits || 0 }}
						</div>
						<div class="feat-number fx-grow-1">
							{{ formatNumeral(totalPatientAmount) }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="fx-grow-1 fx-layout-column fx-content-start fx-items-stretch">
			<div class="payment-caisse mat-elevation-z3 auto">
				<div class="header">{{ 'PAYMENTS_BY_MODE' | translate }}</div>

				<div
					class="payment-grid col-4"
					style="line-height: 28px; font-weight: bold">
					<div></div>
					<div class="calcul title-h">
						{{ 'IMMEDIATE_PAYMENTS' | translate }}
					</div>
					<div class="calcul title-h">
						{{ 'ANTERIOR_FOLDER' | translate }}
					</div>
					<div class="calcul title-h">
						{{ 'ACCUMULATION_OF_MVTS' | translate }}
					</div>
				</div>

				<div class="payment-grid col-4">
					<div>{{ 'CASH' | translate }}</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ state.cash_daily_paid_amount_nbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(state.cash_daily_paid_amount) }}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ state.cash_anterior_paid_amount_nbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(state.cash_anterior_paid_amount) }}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ cashDayAntNbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(cashDayAntAmount) }}
						</div>
					</div>
				</div>

				<div class="payment-grid col-4">
					<div>{{ 'CHECK' | translate }}</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ state.check_daily_paid_amount_nbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(state.check_daily_paid_amount) }}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ state.check_anterior_paid_amount_nbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{
								formatNumeral(state.check_anterior_paid_amount)
							}}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ checkDayAntNbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(checkDayAntAmount) }}
						</div>
					</div>
				</div>

				<div class="payment-grid col-4">
					<div>{{ 'TPE_CREDIT_CARD' | translate }}</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ state.credit_daily_card_paid_amount_nbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{
								formatNumeral(
									state.credit_daily_card_paid_amount
								)
							}}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{
								state.credit_card_anterior_paid_amount_nbr || 0
							}}
						</div>
						<div class="fx-grow-1">
							{{
								formatNumeral(
									state.credit_card_anterior_paid_amount
								)
							}}
						</div>
					</div>
					<div class="calcul fx-layout-row fx-content-space-between">
						<div class="count fx-grow-1">
							{{ cardDayAntNbr || 0 }}
						</div>
						<div class="fx-grow-1">
							{{ formatNumeral(cardDayAntAmount) }}
						</div>
					</div>
				</div>

				<div class="payment-grid col-4 border-top">
					<div style="grid-column: 1 / 4; text-align: right">
						Total ({{ 'IMMEDIATE' | translate }} +
						{{ 'ANTERIORS' | translate }}) :
					</div>
					<div class="calcul feat-number" style="font-weight: bold">
						{{ formatNumeral(totalMode) }}
					</div>
				</div>
			</div>

			<div class="payment-caisse mat-elevation-z3 auto">
				<div class="header">{{ 'TREASURY' | translate }}</div>

				<div
					class="payment-grid col-4"
					style="line-height: 28px; font-weight: bold">
					<div>{{ 'CENTER_TURNOVER' | translate }}</div>
					<div
						class="feat-number"
						style="grid-column: 2 / 5; text-align: center">
						{{ formatNumeral(turnover) }}
					</div>
					<div>{{ 'ANTEROIR_PAID_FOLDERS' | translate }}</div>
					<div class="calcul border-top" style="border-left: none">
						{{ formatNumeral(state.anterior_paid_amount) }}
					</div>
					<div
						class="calcul border-top"
						style="border-left: none"></div>
					<div class="calcul border-top" style="border-left: none">
						{{ state.nb_anterior_visits }} patient(s)
					</div>
				</div>
			</div>
		</div>
	</div>
} @else {
	<div
		class="no-data fx-layout-column fx-content-center fx-items-center fx-gap-32">
		<mat-icon
			class="alert-icon-gradient"
			fontIcon="mdi-alert"
			fontSet="mdi"
			style="font-size: 48px !important"></mat-icon>
		<span>{{ 'NO_DATA' | translate }}</span>
	</div>
}

<button
	[class.printState-icon-gradient]="state"
	(click)="printState()"
	[disabled]="!state"
	[matTooltip]="'PRINT_DAY_STATE' | translate"
	class="print-btn"
	mat-mini-fab>
	<mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
</button>

<button
	[class.printAntState-icon-gradient]="state"
	(click)="printAnteriorState()"
	[disabled]="!state"
	[matTooltip]="'PRINT_ANTERIOR_STATE' | translate"
	class="print-btn ft-left"
	color="primary"
	mat-mini-fab>
	<mat-icon fontSet="mdi" fontIcon="mdi-cloud-print"></mat-icon>
</button>

<button
	[class.printPatient-icon-gradient]="state"
	class="print-btn ft-left-2"
	mat-mini-fab
	[matTooltip]="'STATE_PER_PATIENT' | translate"
	(click)="exportToPdf()">
	<mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
</button>

<button
	[class.printHistory-icon-gradient]="state"
	class="print-btn ft-left-3"
	mat-mini-fab
	[matTooltip]="'PRINTING_HISTORY' | translate"
	(click)="displayPrintingHistory()">
	<mat-icon fontIcon="mdi-printer-eye" fontSet="mdi"></mat-icon>
</button>
