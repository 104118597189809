import { LOCALIZATION } from '../../utils/pivot-i18n-fr';
import { assign, concat } from 'lodash';

export function pivotConfig(cmp): any {
	const config = {
		dataSource: {
			dataSourceType: 'json',
			data: concat(cmp.headers, cmp.dataset),
		},
		formats: [
			{
				name: '',
				decimalPlaces: 0,
				currencySymbol: ' ' + cmp._config.generalSetting.currency,
				currencySymbolAlign: 'right',
				nullValue: '',
			},
			{
				name: 'ft_count',
				thousandsSeparator: ' ',
				decimalSeparator: '.',
				decimalPlaces: 0,
				currencySymbol: '',
				currencySymbolAlign: 'right',
				nullValue: '',
				textAlign: 'right',
			},
			{
				name: 'ft_percent',
				thousandsSeparator: ' ',
				decimalSeparator: '.',
				decimalPlaces: 2,
				currencySymbol: ' %',
				currencySymbolAlign: 'right',
				nullValue: '',
				textAlign: 'right',
			},
		],
		slice: {
			reportFilters: [
				{
					uniqueName: 'patientArrival.Year',
				},
				{
					uniqueName: 'insuranceName',
				},
				{
					uniqueName: 'aet',
				},
			],
			rows: [
				{
					uniqueName: 'patientArrival.Month',
				},
				{
					uniqueName: 'performingPhysician',
				},
				{
					uniqueName: 'examType',
				},
				{
					uniqueName: 'procedureCode',
				},
			],
			columns: [
				{
					uniqueName: 'Measures',
				},
			],
			measures: [
				{
					uniqueName: 'totalAmount',
					format: 'currency',
					aggregation: 'sum',
				},
				{
					uniqueName: 'paidAmount',
					format: 'currency',
					aggregation: 'sum',
				},
				/*                {
                                    uniqueName: "globalDiscount",
                                    format: "currency",
                                    aggregation: "sum",
                                },
                                {
                                    uniqueName: "leftAmount",
                                    format: "currency",
                                    aggregation: "sum",
                                },*/
				{
					uniqueName: 'total_exams',
					formula: 'count("procedureCode") ',
					caption: 'Nombre des examens',
					format: 'ft_count',
				},
				/*                {
                    uniqueName: "percent_physician",
                    formula: "sum(\"paidAmount\") * 0.25 ",
                    caption: "Part Radiologue"
                },*/
				{
					uniqueName: 'billed_total',
					formula: 'count("billed") ',
					caption: '# facturés',
					format: 'ft_count',
				},
				{
					uniqueName: 'billed_percent',
					formula: 'count("billed") * 100 / count("procedureCode")',
					caption: '% facturé',
					format: 'ft_percent',
					active: true,
				},
			],
			flatOrder: [
				'patientName',
				'patientID',
				'patientArrival',
				'insuranceName',
				'procedureCode',
				'examType',
				'performingPhysician',
				'referringPhysician',
				'numberOfFilms',
				'urgent',
				'billed',
				'modality',
				'price',
				'paymentStatus',
				'paidAmount',
				'leftAmount',
				'discount',
			],
		},
		conditions: [
			{
				formula: '#value <= 100',
				format: {
					backgroundColor: '#ffbbc9',
					color: '#323232',
				},
				measure: 'leftAmount',
			},
			{
				formula: 'AND(#value > 100, #value <= 200)',
				format: {
					backgroundColor: '#fd627e',
					color: '#000000',
				},
				measure: 'leftAmount',
			},
			{
				formula: 'AND(#value > 200, #value <= 300)',
				format: {
					backgroundColor: '#cd3355',
					color: '#ffffff',
				},
				measure: 'leftAmount',
			},
			{
				formula: '#value > 300',
				format: {
					backgroundColor: '#a70d2c',
					color: '#FFFFFF',
				},
				measure: 'leftAmount',
			},
			{
				formula: '#value > 0',
				format: {
					color: '#a70d2c',
					fontWeight: 'bold',
				},
				measure: 'emergency_percent',
			},
			{
				formula: '#value > 0',
				format: {
					color: '#a70d2c',
					fontWeight: 'bold',
				},
				measure: 'emergency_total',
			},
			{
				formula: '#value > 0',
				format: {
					color: '#035f4c',
					fontWeight: 'bold',
				},
				measure: 'billed_percent',
			},
			{
				formula: '#value > 0',
				format: {
					color: '#035f4c',
					fontWeight: 'bold',
				},
				measure: 'billed_total',
			},
		],
		options: {
			grid: {
				type: 'compact',
				showTotals: 'on',
				showGrandTotals: 'on',
				showHeaders: false,
				title: '',
				showHierarchies: true,
				showHierarchyCaptions: true,
				showReportFiltersArea: true,
			},
		},
	};

	if (cmp._config.appLang === 'fr')
		assign(config, { localization: LOCALIZATION });
	return config;
}

export function pivotHeader(cmp: any) {
	return {
		id: {
			type: 'number',
			caption: cmp._translate.instant('ID'),
		},
		patientArrival: {
			type: 'date',
			caption: cmp._translate.instant('PATIENT_ARRIVAL'),
		},
		aet: {
			type: 'string',
			caption: cmp._translate.instant('AET'),
		},
		appointmentDateTime: {
			type: 'date',
			caption: cmp._translate.instant('RDV_DATE'),
		},
		reportSignature: {
			type: 'date',
			caption: cmp._translate.instant('REPORT_SIGNATURE'),
		},
		patientID: {
			type: 'string',
			caption: cmp._translate.instant('PATIENT_ID'),
		},
		insuranceName: {
			type: 'string',
			caption: cmp._translate.instant('INSURANCE'),
		},
		examType: {
			type: 'string',
			caption: cmp._translate.instant('PROCEDURE_TYPE'),
		},
		patientName: {
			type: 'string',
			caption: cmp._translate.instant('PATIENT_NAME'),
		},
		procedureCode: {
			type: 'string',
			caption: cmp._translate.instant('PROCEDURE_CODE'),
		},
		referringPhysician: {
			type: 'string',
			caption: cmp._translate.instant('REFERRING_PHYSICIAN'),
		},
		performingPhysician: {
			type: 'string',
			caption: cmp._translate.instant('PERFORMING_PHYSICIAN'),
		},
		reportingStatus: {
			type: 'string',
			caption: cmp._translate.instant('REPORTING_STATUS'),
		},
		patientClass: {
			type: 'string',
			caption: cmp._translate.instant('PATIENT_CLASS'),
		},
		patientStatus: {
			type: 'string',
			caption: cmp._translate.instant('PATIENT_STATUS'),
		},
		price: {
			type: 'number',
			caption: cmp._translate.instant('PRICE'),
		},
		leftAmount: {
			type: 'number',
			caption: cmp._translate.instant('LEFT_AMOUNT'),
		},
		paidAmount: {
			type: 'number',
			caption: cmp._translate.instant('PAID_AMOUNT'),
		},
		globalDiscount: {
			type: 'number',
			caption: cmp._translate.instant('GLOBAL_DISCOUNT'),
		},
		totalAmount: {
			type: 'number',
			caption: cmp._translate.instant('TOTAL_AMOUNT'),
		},
		discount: {
			type: 'number',
			caption: cmp._translate.instant('DISCOUNT'),
		},
		paymentStatus: {
			type: 'string',
			caption: cmp._translate.instant('PAYMENT_STATUS'),
		},
		modality: {
			type: 'string',
			caption: cmp._translate.instant('MODALITY'),
		},
		billed: {
			type: 'boolean',
			caption: cmp._translate.instant('BILLED'),
		},
		urgent: {
			type: 'boolean',
			caption: cmp._translate.instant('URGENT'),
		},
		technician: {
			type: 'string',
			caption: cmp._translate.instant('TECHNICIAN'),
		},
		numberOfFilms: {
			type: 'number',
			caption: cmp._translate.instant('NUMBER_OF_FILMS'),
		},
	};
}
