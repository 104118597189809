import { Type } from '@angular/core';

export class StatisticItem {
	static load = (component: Type<any>, data: any) =>
		new StatisticItem(component, data);
	constructor(
		public component: Type<any>,
		public data: any
	) {}
}
