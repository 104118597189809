export const RPH_TABLE_CONF = [
	{
		label: 'physicianName',
		header: 'REFERRING_PHYSICIAN',
		value: 'physicianName',
		sortField: 'physicianName',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: false,
		hidden: false,
		searchable: true,
		required: true,
		order: 1,
	},
	{
		label: 'totalPatients',
		header: 'TOTAL_PATIENTS',
		value: 'totalPatients',
		sortField: 'totalPatients',
		type: 'number',
		unit: null,
		defaultValue: '',
		sortable: false,
		hidden: false,
		searchable: false,
		required: false,
		order: 2,
	},
	{
		label: 'totalExams',
		header: 'TOTAL_EXAMS',
		value: 'totalExams',
		sortField: 'totalExams',
		type: 'number',
		unit: null,
		defaultValue: '',
		sortable: false,
		hidden: false,
		searchable: false,
		required: false,
		order: 3,
	},
	{
		label: 'totalPaidAmount',
		header: 'TOTAL_PAID',
		value: 'totalPaidAmount',
		sortField: 'totalPaidAmount',
		type: 'number',
		unit: null,
		defaultValue: '',
		sortable: false,
		hidden: false,
		searchable: false,
		required: false,
		order: 4,
	},
];
