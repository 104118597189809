<mat-card class="fx-card fx-fill-height fx-layout-column-nowrap">
	<div
		class="fx-search-area fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-8 fx-padding-8"
		[formGroup]="filterForm">
		<mat-form-field subscriptSizing="dynamic" class="fx-grow-1">
			<mat-icon
				matIconPrefix
				fontIcon="mdi-magnify"
				fontSet="mdi"></mat-icon>
			<input
				matInput
				formControlName="key"
				placeholder="{{ 'SEARCHING' | translate }}" />
		</mat-form-field>

		<mat-button-toggle-group
			aria-label="category"
			multiple
			name="labelModule"
			formControlName="category">
			@for (category of ['Receipt', 'Report', 'Ticket']; track category) {
				<mat-button-toggle [value]="category">
					<mat-icon
						[ngStyle]="{
							background:
								'linear-gradient(45deg, black, ' +
								FILES_CATEGORIES_COLORS[category] +
								', white)',
							'-webkit-background-clip': 'text',
							'-webkit-text-fill-color': 'transparent'
						}"
						fontSet="mdi"
						[fontIcon]="FILES_CATEGORIES_ICONS[category]"></mat-icon
					>&nbsp; {{ category | translate }}
				</mat-button-toggle>
			}
		</mat-button-toggle-group>

		<mat-form-field subscriptSizing="dynamic" style="width: 220px">
			<mat-date-range-input [rangePicker]="picker">
				<input
					[placeholder]="'START_DATE2' | translate"
					formControlName="startDate"
					matStartDate />
				<input
					[placeholder]="'END_DATE' | translate"
					formControlName="endDate"
					matEndDate />
			</mat-date-range-input>
			<mat-datepicker-toggle
				[for]="picker"
				matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>
		</mat-form-field>
	</div>

	<div class="fx-layout-column-nowrap fx-fill-height">
		<div class="fx-fill-height fx-overflow-auto">
			<mat-table
				[dataSource]="dataSource"
				matSort
				matSortActive="date"
				matSortDirection="desc"
				[trackBy]="trackByName"
				style="height: calc(100vh - 184px)">
				<!--Table-->
				@for (col of displayedColumns; track col) {
					<ng-container
						[class.hidden]="col.hidden"
						matColumnDef="{{ col.label }}">
						<mat-header-cell *matHeaderCellDef mat-sort-header>
							{{ col.header | translate }}
						</mat-header-cell>

						@if (col.unit === 'date') {
							<mat-cell *matCellDef="let row">
								{{
									row[col.label] | date: 'dd/MM/yyyy HH:mm'
								}}</mat-cell
							>
						}
						@if (col.unit !== 'date' && col.unit !== 'color') {
							<mat-cell *matCellDef="let row"
								><span (click)="open(row)">{{
									row[col.label]
								}}</span></mat-cell
							>
						}
						@if (col.unit === 'color') {
							<mat-cell *matCellDef="let row">
								<mat-icon
									[matTooltip]="row[col.label] | translate"
									[ngStyle]="{
										background:
											'linear-gradient(45deg, black, ' +
											FILES_CATEGORIES_COLORS[
												row[col.label]
											] +
											', white)',
										'-webkit-background-clip': 'text',
										'-webkit-text-fill-color': 'transparent'
									}"
									fontSet="mdi"
									[fontIcon]="
										FILES_CATEGORIES_ICONS[row[col.label]]
									"></mat-icon>
							</mat-cell>
						}
					</ng-container>
				}

				<!--actions-->
				<ng-container matColumnDef="action">
					<mat-header-cell *matHeaderCellDef></mat-header-cell>
					<mat-cell *matCellDef="let row">
						<button
							mat-icon-button
							(click)="download(row)"
							[matTooltip]="'DOWNLOAD' | translate">
							<mat-icon
								fontSet="mdi"
								fontIcon="mdi-cloud-download"></mat-icon>
						</button>

						<button
							mat-icon-button
							(click)="print(row)"
							[matTooltip]="'PRINT' | translate">
							<mat-icon
								fontSet="mdi"
								fontIcon="mdi-printer"></mat-icon>
						</button>

						<button
							mat-icon-button
							(click)="open(row)"
							[matTooltip]="'OPEN' | translate">
							<mat-icon
								fontSet="mdi"
								fontIcon="mdi-open-in-new"></mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<!--header-->
				<mat-header-row
					*matHeaderRowDef="columnsToDisplay"></mat-header-row>
				<mat-row
					[@rowsAnimation]=""
					*matRowDef="let row; columns: columnsToDisplay"
					[ngClass]="{ 'fire-row': true }"></mat-row>
			</mat-table>
			<div
				[style.display]="resultsLength === 0 ? '' : 'none'"
				class="fx-table-empty">
				@if (isLoadingResults) {
					<div>
						<mat-spinner
							[diameter]="50"
							[strokeWidth]="3"
							color="warn"></mat-spinner>
					</div>
				}
			</div>
		</div>
		<mat-paginator
			[length]="resultsLength"
			[pageIndex]="0"
			[pageSize]="25"
			[pageSizeOptions]="[5, 10, 25, 50, 100]"
			[showFirstLastButtons]="true">
		</mat-paginator>
	</div>
</mat-card>
