import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	inject,
	Input,
	OnDestroy,
} from '@angular/core';
import { FtState, StateComponent, ViewQuery } from '../model';
import { StatisticService } from '../statistic.service';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
	selector: 'ft-patient-state',
	templateUrl: './patient-state.component.html',
	styleUrls: ['./patient-state.component.scss'],
})
export class PatientStateComponent
	implements StateComponent, OnDestroy, AfterViewInit
{
	@Input() data: ViewQuery;

	qualitativeValues = [
		'maritalStatus',
		'city',
		'confidentiality',
		'patientClass',
		'sex',
		'ageRange',
		'insuranceName',
		'imagingCenter',
	];

	dataMap: {
		label: string;
		data: FtState[];
		dataset: any[];
		chartLabels: any[];
		chartType: any;
	}[] = [];

	private CHART_TYPES = {
		maritalStatus: 'bar',
		city: 'bar',
		confidentiality: 'pie',
		patientClass: 'pie',
		sex: 'pie',
		insuranceName: 'bar',
		ageRange: 'bar',
		imagingCenter: 'pie',
	};
	private sub: Subscription;
	private _service = inject(StatisticService);

	ngAfterViewInit() {
		const start = moment(this.data.startDate).format('YYYYMMDD');
		const end = moment(this.data.endDate).format('YYYYMMDD');

		this.sub = this._service
			.getPatientStates(`${start}-${end}`)
			.subscribe(data => {
				this.dataMap = this.qualitativeValues.map(it => {
					const stateData = this.countDataByAttribute(data, it);

					return {
						label: it,
						data: stateData,
						dataset: stateData.map(it => it.count),
						chartLabels: stateData.map(v => {
							return ['none', ''].includes(v.value)
								? 'Autre'
								: v.value;
						}),
						chartType: this.CHART_TYPES[it],
					};
				});
			});
	}

	countDataByAttribute(data: any, attr: string): FtState[] {
		const obj = data[attr];
		return Object.keys(obj).map(it =>
			FtState.of(it === 'none' ? 'Autre' : it, obj[it])
		);
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}
}
