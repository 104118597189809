export enum ExamStateView {
	GENERAL_VIEW = 'GENERAL_VIEW',
	PRINT_FILES = 'PRINT_FILES',
	PERFORMING_RADIOLOGIST = 'PERFORMING_RADIOLOGIST',
	TECHNICIANS = 'TECHNICIANS',
	REFERRING_PHYSICIAN = 'REFERRING_PHYSICIAN',
	AET_S = 'AET_S',
	REASONS_FOR_EXAM = 'REASONS_FOR_EXAM',
}

export enum PatientStateView {
	CITY = 'CITY',
	AGE = 'AGE',
	SEX = 'SEX',
	DATE = 'DATE',
	PATIENT = 'PATIENT',
}

export enum PaymentStateView {
	PAYMENT = 'PAYMENT',
	DATE = 'DATE',
}

export const VIEWS = {
	EXAM: Object.keys(ExamStateView),
	PATIENT: Object.keys(PatientStateView),
	PAYMENT: Object.keys(PaymentStateView),
};

export class FtState {
	static of = (value: string, count: number): FtState =>
		new FtState(value, count);
	constructor(
		public value?: string,
		public count?: number
	) {}
}
