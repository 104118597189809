<div
	class="fx-fill fx-layout-row-nowrap fx-content-space-between fx-items-center fx-gap-4">
	<div class="fx-grow-1 fx-layout-column-nowrap fx-fill-width">
		@if (pvTable === 'wdr') {
			<div
				class="ft-toolbar fx-layout-row-nowrap fx-content-start fx-gap-8">
				<button
					[matMenuTriggerFor]="exportMenu"
					[matTooltip]="'EXPORT' | translate"
					mat-icon-button>
					<mat-icon
						fontIcon="mdi-cloud-download"
						fontSet="mdi"></mat-icon>
				</button>
				<mat-menu #exportMenu="matMenu">
					<button (click)="exportTo('pdf')" mat-menu-item>
						<mat-icon
							fontIcon="mdi-file-pdf"
							fontSet="mdi"></mat-icon>
						Export PDF
					</button>
					<button (click)="exportTo('excel')" mat-menu-item>
						<mat-icon
							fontIcon="mdi-file-excel"
							fontSet="mdi"></mat-icon>
						Export Excel
					</button>
					<button (click)="exportTo('html')" mat-menu-item>
						<mat-icon
							fontIcon="mdi-language-html5"
							fontSet="mdi"></mat-icon>
						Export HTML
					</button>
				</mat-menu>

				<span class="fx-grow-1"></span>

				<button
					(click)="openFields()"
					[matTooltip]="'FIELDS' | translate"
					mat-icon-button>
					<mat-icon fontIcon="mdi-cog" fontSet="mdi"></mat-icon>
				</button>
				<button
					(click)="openOptions()"
					[matTooltip]="'OPTIONS' | translate"
					mat-icon-button>
					<mat-icon fontIcon="mdi-cogs" fontSet="mdi"></mat-icon>
				</button>

				<button
					[matMenuTriggerFor]="formatMenu"
					[matTooltip]="'FORMAT' | translate"
					mat-icon-button>
					<mat-icon fontIcon="mdi-table-cog" fontSet="mdi"></mat-icon>
				</button>
				<mat-menu #formatMenu="matMenu">
					<button (click)="openFormatting()" mat-menu-item>
						<mat-icon
							fontIcon="mdi-format-paint"
							fontSet="mdi"></mat-icon>
						Conditional formatting
					</button>
					<button (click)="createFormattingItem()" mat-menu-item>
						<mat-icon
							fontIcon="mdi-table-column"
							fontSet="mdi"></mat-icon>
						Format cell
					</button>
				</mat-menu>

				<button
					(click)="printReport()"
					[matTooltip]="'PRINT' | translate"
					mat-icon-button>
					<mat-icon fontIcon="mdi-printer" fontSet="mdi"></mat-icon>
				</button>
				<button
					(click)="goFullScreen()"
					[matTooltip]="'FULL_SCREEN' | translate"
					mat-icon-button>
					<mat-icon
						fontIcon="mdi-fullscreen"
						fontSet="mdi"></mat-icon>
				</button>
			</div>
		}

		@switch (pvTable) {
			@case ('fm') {
				<fm-pivot
					#pivot
					(beforetoolbarcreated)="beforeToolbarCreated($event)"
					(ready)="onPivotReady($event)"
					(reportcomplete)="onReportComplete()"
					[customizeCell]="onCustomizeCell"
					[height]="'calc(100vh - 170px)'"
					[licenseKey]="flexMonsterLicenseKey"
					[report]="config"
					[toolbar]="true"
					[width]="'100%'">
				</fm-pivot>
			}
			@default {}
		}
	</div>
</div>
