import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PRINTER_URL, SCHEDULER_URL, STATISTIC_URL } from '../urls';
import { AttributeState, PaymentState } from '../model';
import { tap } from 'rxjs/operators';
import { printBlob } from '../utils';
import moment from 'moment';

@Injectable()
export class StatisticService {
	private _http = inject(HttpClient);

	getPhysicianExams(filter: string): Observable<any> {
		const params = { filter };
		return this._http.get(`${STATISTIC_URL}/physicianExams`, { params });
	}

	getTechniciansExams(filter: string): Observable<any> {
		const params = { filter };
		return this._http.get(`${STATISTIC_URL}/techniciansExams`, { params });
	}

	getExamsByAet(dateRange: string): Observable<AttributeState[]> {
		const params = { dateRange };
		return this._http.get<AttributeState[]>(`${STATISTIC_URL}/examsByAet`, {
			params,
		});
	}

	getExamsByType(dateRange: string): Observable<any> {
		const params = { dateRange };
		return this._http.get(`${STATISTIC_URL}/examsByType`, { params });
	}

	todayPayments(date: string, filter: string): Observable<PaymentState> {
		return this._http.get<PaymentState>(`${SCHEDULER_URL}/todayPayments`, {
			params: { date, filter },
		});
	}

	getPatientStates(dateRange: string): Observable<any> {
		const params = { dateRange };
		return this._http.get(`${STATISTIC_URL}/patientStates`, { params });
	}

	getExamsPerReferringPhysicians(
		pageSize: number,
		pageIndex: number,
		sort: string,
		direction: string,
		dateRange: string,
		physicianId?: number
	): Observable<any> {
		const params = {
			page: String(pageIndex),
			size: String(pageSize),
			sort: `${sort},${direction}`,
			dateRange,
			physicianId,
		};
		return this._http.get(`${STATISTIC_URL}/examsPerReferringPhysician`, {
			params,
		});
	}

	exportData(
		userType: string,
		userId: any,
		startDate: string,
		endDate: string,
		toType: 'PDF' | 'EXCEL'
	): Observable<any> {
		const params = { userType, userId, startDate, endDate, toType };
		return this._http.get(`${STATISTIC_URL}/export-data`, {
			params,
			responseType: 'blob',
		});
	}

	printState(
		date: string,
		filter: string,
		withDetails: any
	): Observable<any> {
		return this._http
			.get(
				`${PRINTER_URL}/printState?v=${moment().format('YYYYMMDDHHmmssSSS')}`,
				{
					params: {
						date,
						filter,
						withDetails,
					},
					responseType: 'blob',
				}
			)
			.pipe(tap(printBlob));
	}

	printAnteriorState(): Observable<any> {
		return this._http
			.get(`${PRINTER_URL}/printAnteriorState`, { responseType: 'blob' })
			.pipe(tap(printBlob));
	}
}
